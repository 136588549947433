import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { ScreenReaderOnly } from '@twilio-paste/screen-reader-only';
import { Text } from '@twilio-paste/text';
import { Flex } from '@twilio-paste/flex';
import { Paragraph } from '@twilio-paste/paragraph';
import { Heading } from '@twilio-paste/heading';
import { Card } from '@twilio-paste/card';
import { Button } from '@twilio-paste/button';
import { Anchor } from '@twilio-paste/anchor';
import { Table, THead, TBody, Tr, Td, Th } from '@twilio-paste/table';
import Changelog from '@twilio-paste/screen-reader-only/CHANGELOG.md';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { InlineCode } from '../../../components/Typography';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/screen-reader-only"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/screen-reader-only/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Screen Reader Only" categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/screen-reader-only" storybookUrl="/?path=/story/components-screen-reader-only--default" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Screen Reader Only`}</h3>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <p>{`When wrapped with the Screen Reader Only component, content is visually hidden but still available to assistive technology. This can be useful when information or relationships between UI controls are only communicated `}<em parentName="p">{`visually`}</em>{`. By being able to visually hide some content you are able to provide clarifying information to non-sighted users without adding redundancy to the visual design.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Providing visually hidden headings to sectioned content`}</h3>
        <p>{`All sectioning elements, such as Cards, should contain a heading to provide a clear document outline and form of navigation to assistive technology users. However, there may be times a heading is not required (or even redundant) in a particular UI for sighted users. By using the Screen Reader Only component you can provide a visually hidden heading to give additional context and heirarchy. In the example below, we've added "Flex" as a heading that is accessible to assistive technology, but hidden visually.`}</p>
        <LivePreview scope={{
          Card,
          ScreenReaderOnly,
          Heading,
          Paragraph,
          Button,
          Anchor,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Card>
  <ScreenReaderOnly as="div">
    <Heading as="h2" variant="heading20">Flex</Heading>
  </ScreenReaderOnly>
  <Paragraph>
    Deploy an omnichannel contact center within minutes and customize every element of the experience, including the agent desktop, channels, interaction routing, and reporting using common web frameworks like React.js and REST APIs.
  </Paragraph>
  <Flex vAlignContent="center">
    <Flex marginRight="space40">
      <Button variant="primary">
        Launch Flex
      </Button>
    </Flex>
    <Anchor href="#">
      View all projects
    </Anchor>
  </Flex>
</Card>`}
        </LivePreview>
        <h3>{`Providing clear actions while still reducing visual repetition`}</h3>
        <p>{`Each column in a table is required to have a column header. Sometimes those column headers can be visually hidden from sighted users if desired. To do so use the Screen Reader Only component to hide the cell content for that column header.`}</p>
        <p>{`Another common use case is for providing correct context to repetitive actions. In the example below, the "Buy" button is clearly visually associated with the phone number via row highlighting. However, to a screen reader user, this association may not be as clear. To provide additional clarification we can add `}<em parentName="p">{`what`}</em>{` you would be buying (Buy ) as visually hidden text to the button. This way, we can keep the same level of context for all users.`}</p>
        <LivePreview scope={{
          Table,
          THead,
          TBody,
          Tr,
          Td,
          Th,
          Button,
          Text,
          ScreenReaderOnly
        }} language="jsx" mdxType="LivePreview">
  {`<Table>
  <THead>
    <Tr>
      <Th>Number</Th>
      <Th>Type</Th>
      <Th>Monthly Fee</Th>
      <Th>
        <ScreenReaderOnly>Actions</ScreenReaderOnly>
      </Th>
    </Tr>
  </THead>
  <TBody>
    <Tr>
      <Th>
        <strong>+1 (903) 603-9469</strong>
        <Text as="div" color="colorTextWeak" fontWeight="normal">
          Mabank, TX
        </Text>
      </Th>
      <Td>local</Td>
      <Td>$1.00</Td>
      <Td>
        <Button variant="secondary" size="small">
          Buy <ScreenReaderOnly>+1 (903) 603-9469</ScreenReaderOnly>
        </Button>
      </Td>
    </Tr>
    <Tr>
      <Th>
        <strong>+1 (470) 309-0026</strong>
        <Text as="div" color="colorTextWeak" fontWeight="normal">
          Adairsville, GA
        </Text>
      </Th>
      <Td>local</Td>
      <Td>$1.00</Td>
      <Td>
        <Button variant="secondary" size="small">
          Buy <ScreenReaderOnly>+1 (470) 309-0026</ScreenReaderOnly>
        </Button>
      </Td>
    </Tr>
    <Tr>
      <Th>
        <strong>+1 (484) 662-4088</strong>
        <Text as="div" color="colorTextWeak" fontWeight="normal">
          Hamburg, PA
        </Text>
      </Th>
      <Td>local</Td>
      <Td>$1.00</Td>
      <Td>
        <Button variant="secondary" size="small">
          Buy <ScreenReaderOnly>+1 (484) 662-4088</ScreenReaderOnly>
        </Button>
      </Td>
    </Tr>
  </TBody>
</Table>`}
        </LivePreview>
        <h2>{`When to use Screen Reader Only`}</h2>
        <p>{`Use the Screen Reader Only component when you are required to provide additional or contextual information to a screen reader user which a sighted user maybe able to infer and when you cannot do so via semantic HTML.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use to provide additional text content to users of assistive technology" preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don't use to hide interactive content" preview={false} mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/screen-reader-only - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {ScreenReaderOnly} from '@twilio-paste/screen-reader-only';

const Component = () => (
  <Button variant="secondary" size="small">
    Buy <ScreenReaderOnly>+1 (484) 662-4088</ScreenReaderOnly>
  </Button>
);
`}</code></pre>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`keyof JSX.IntrinsicElements`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A custom HTML tag`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`span`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      